span,
div {
  .d-price {
    // color: rgb(69, 71, 161);
    color: #11152A;
    font-family: Konnect;
    font-size: 20px;
    font-weight: 700;
  }
}
span,
div {
  .d-price-linethrough {
    text-decoration: line-through;
    font-size: 20px;
    padding: 0 5px;
    font-family: Konnect;
    margin: 0 5px;
    color: rgb(174, 174, 174);
  }
}

.section-banner {
  height: 150px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
