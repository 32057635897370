.css-o4b71y-MuiAccordionSummary-content.Mui-expanded{
    margin:10px 0;
    background-color: #f5f5f5;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
    background-color: #f5f5f5;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{
    padding: 0 10px;
}
.newFob_css{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  .new_title_css{
    min-width: 80px;
    width: 200px;
    // padding-left: 10px;
  }
}
.newFob_css:first-child{
    margin-top: 0px;
}
