.pagination {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.table_cell {
    text-align: center;
}

.dialog_title {
    text-align: center;
}