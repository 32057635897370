$mainColor: #002ea6;
$secondColor: #0030a8b3;
$secondHoverColor: #0030a8e6;
.swiper {
  .swiper-button-prev,
  .swiper-button-next {
    color: #fff;
    background: rgba(94, 95, 94, 0.6);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    &:after {
      font-size: 24px;
    }
    opacity: 0;
  }
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 1;
    }
  }
  &.small-button {
    .swiper-button-prev,
    .swiper-button-next {
      color: #fff;
      background: rgba(94, 95, 94, 0.6);
      border-radius: 50%;
      width: 24px;
      height: 24px;
      margin-top: -12px;
      &:after {
        font-size: 12px;
      }
    }
  }
  &.show-btn {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 1;
    }
  }
  .swiper-pagination {
    bottom: 0;
  }
}

body {
  // min-width: 1400px;
  overflow-x: auto;
}

.DateInput_input {
  line-height: 24px;
}
.DateRangePickerInput__showClearDates {
  position: relative;
}
.DateInput_input__focused {
  border-bottom: 2px solid $mainColor;
}
.CalendarDay__selected {
  background: $mainColor;
  border: 1px solid $mainColor;
  color: #fff;
  &:hover {
    background: $mainColor;
    border: 1px solid $mainColor;
    color: #fff;
  }
}
.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  background: $secondColor;
  border: 1px solid $secondColor;
  color: #fff;
  &:hover {
    background: $secondHoverColor;
    border: 1px solid $secondHoverColor;
    color: #fff;
  }
}

.PrivateSwipeArea-root {
  width: 0 !important;
}

@keyframes slide {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
