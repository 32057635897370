.css-xb63p6-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border: 2px solid #ff1744;
}


.merchantIcon {
    color: #002EA6;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 1px solid #002EA6;
    padding: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    margin-left: 20px;

    img {
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 5px;
    }
}

.merchantIcon:hover {
    background-color: #e5e9f9;
}

.content_btn {
    width: 100%;
    text-align: end;
    margin-top: 5px;

    .first_btn {
        background: #FFA13B;
        border-radius: 4px;
        color: #ffffff;
        display: flex;
        font-size: 14px;
        align-items: center;
        padding: 2px 8px 2px 8px;
        font-weight: 500;
        cursor: pointer;

        img {
            width: 15px;
            height: 15px;
            display: inline-block;
            margin-right: 5px;
        }
    }

    .first_btn:hover {
        background-color: #e7a45c;
    }
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 100%;
}

.closeIconCss {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
}

.imageBox {
    line-height: 10px;
    position: absolute;
    top: 5px;
    right: 10px;
}

.fobOrder_css {
    line-height: 10px;
    position: absolute;
    top: 5px;
    right: 10px;
}

.fobOrderIcon {
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-top: 12px;
    cursor: pointer;
}

.btn_content {
    height: auto;
    background: #ffffff;
    border-radius: 10px;
    padding: 15px;

    .top_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 5px;
        border-bottom: 1px solid #E6E6E6;

        .depost_image {
            display: flex;
            align-items: center;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #002EA6;

            .depositIcon {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }

    }

    .btn_autit {
        font-family: Source Han Sans CN;

        .sub_css {
            width: 240px;
            padding: 10px;
            background-color: #002EA6;
            border: 1px solid #002EA6;
            color: #ffffff;
            border-radius: 30px;
            cursor: pointer;
        }

        .sub_css:hover {
            background-color: #092c89;
            border: 1px solid #092c89;
        }

        .purchase_images {
            width: 150px;
            height: 150px;
            border: 1px solid #e8e8e8;
            border-radius: 8px;
            text-align: center;
            margin-right: 10px;
            color: #ccc;

            .no_data_image {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .video_css {
            display: flex;
            align-items: center;
            width: 150px;
            height: 150px;
            border: 1px solid #e8e8e8;
            border-radius: 8px;
            text-align: center;
            margin-right: 10px;
            color: #ccc;
            .video-js.vjs-fluid:not(.vjs-audio-only-mode), .video-js.vjs-16-9:not(.vjs-audio-only-mode), .video-js.vjs-4-3:not(.vjs-audio-only-mode), .video-js.vjs-9-16:not(.vjs-audio-only-mode), .video-js.vjs-1-1:not(.vjs-audio-only-mode){
                height: 100%;
            }
        }

        .dep_amount {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #CCCCCC;
        }

        .doc_upload {
            padding-left: 20px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            cursor: pointer;

            .doc_image_box {
                color: #09B84B;
                display: flex;
                align-items: center;

                img {
                    height: 20px;
                    width: 20px;
                    display: inline-block;
                    margin-right: 10px;



                }
            }

            .doc_image_box:hover {
                font-weight: 600;
            }

            .doc_image_tip {
                color: red;
                cursor: default;
            }
        }

        .ver_image {
            display: flex;
            flex-wrap: wrap;

        }

        .image_title {
            margin-right: 10px;
            min-width: 125px;
            text-align: end;
        }
    }
}

.btn_css,
.btn_css:hover {
    background: #E6E6E6;
    border-radius: 4px;
    color: #000000;
    margin-right: 10px;
    margin-top: 10px;
}


.btn_css_active,
.btn_css_active:hover {
    background: #002EA6;
    border-radius: 4px;
    color: #ffffff;
    margin-right: 10px;
    margin-top: 10px;

}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 100% !important;
}

.css-uhb5lp {
    max-width: 100% !important;
}

::-webkit-scrollbar-thumb {
    width: 8px;
    height: 8px;
    background-color: #e5e5e5;
    border-radius: 10px;
}

// 订购合同样式
.contract_box {
    display: flex;
    width: 100%;
    justify-content: space-around;

    .cg_box,
    .zz_box {
        width: 48%;
        height: auto;
        border-radius: 2px;
        border: 1px solid #e5e5e5;

    }

    .cg_box,
    .zz_box {

        .cg_title,
        .zz_title {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background-color: #e5e5e5;
            border-top-right-radius: 2px;
            border-top-left-radius: 2px;
            font-size: 14px;
            position: relative;
        }

        .content_box {
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;

            .content_item {
                padding: "10px 15px";
                width: 25%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-bottom: 1px solid #cccccc;
                border-right: 1px solid #cccccc;

            }

            .content_input {
                height: 40px;
                line-height: 40px;
                width: 75%;
                border-bottom: 1px solid #cccccc;

            }

            .css-xb63p6-MuiInputBase-root-MuiOutlinedInput-root {
                border-radius: 0px;
            }
        }

    }

    .cg_btns {
        position: absolute;
        top: 0;
        right: 0;
    }

}

.agreement_box {
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;

    .agreement_title {
        width: 100%;
        font-weight: 600;
        font-size: 15px;
    }

    .css-mhc70k-MuiGrid-root {
        margin-top: 0px;
    }

    .item_box_grid {
        color: #000000;
        font-size: 15px;
        min-width: 130px;
        padding-top: 10px;
        padding-right: 10px;
        text-align: end;
    }

    .agreement_address {
        margin-top: 15px;

        .css-mhc70k-MuiGrid-root>.MuiGrid-item {
            padding-top: 0px;
        }
    }

    .table_box {
        height: auto;
        width: "100%";
        border-radius: 2px;
        border: 1px solid #e5e5e5;
        margin-top: 20px;

        .content_css {
            text-align: center;

            .content_image_box {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .content_image {
                width: 60px;
                height: 60px;
                cursor: pointer;

                .css-1uig0wl {
                    width: 60px;
                    height: 60px;
                    border-radius: 4px;
                }
            }

        }

        .caozuo_box {
            text-align: center;
            width: 120px;
            cursor: pointer;

            .add_goods {
                color: blue;
            }

            .del_goods {
                color: red;
            }
        }

        .xuhao {
            width: 100px;
        }

        .btn_box {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;

            .first_box {
                width: 50%;
            }

            .second_box {
                width: 50%;
            }
        }

        .css-1idinpx-MuiFormHelperText-root {
            height: 0px;
            margin-top: 0px;
        }
        .css-1wuyyne{
            height: 0px;
            margin-top: 0px;
        }
    }

    .xiahuagang_box {
        width: 99.5%;
        height: 1px;
        margin: 0 auto;
        border: 1px dashed #E6E6E6;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .descripution_box {
        height: auto;
        width: 100%;

        .des_title {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 600;
            color: #010101;

        }

        .des_way {
            display: flex;
            padding-left: 10px;
            margin-top: 10px;
            width: 100%;

            .methed_way {
                margin-left: 10px;

                .input_way {
                    display: flex;
                    align-items: center;

                    .ykuan_box {
                        width: 100px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        border-bottom: 1px solid #cccccc;
                    }

                    .total_price_css {
                        text-align: center;
                        min-width: 100px;
                        border-bottom: 1px solid #ccc;
                    }
                }

                .day_box {
                    display: flex;
                    align-items: center;


                }
            }

            .methed_way_two {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                width: 80%;
                margin-left: 10px;
            }

        }
    }

    .btn_signature {
        width: 98%;
        display: flex;
        margin: 0 auto;
        margin-top: 20px;

        .left_box,
        .right_box {
            width: 50%;

            .qianming_box,
            .riq_box {
                height: 40px;
                line-height: 40px;
                font-weight: 600;
                font-size: 15px;
                display: flex;
                .status_first{
                    color: #e09304;
                    font-weight: 500;
                    padding-left: 5px;
                }
                .status_second{
                    color: #02a10a;
                    font-weight: 500;
                    padding-left: 5px;
                }
            }
        }
    }

    .btn_button {
        width: 100%;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px;

        .queren_btn,
        .sunbmit_btn {
            width: 140px;
            height: 35px;
            background: #002EA6;
            border-radius: 5px;
            color: #ffffff;
        }

        .queren_btn:hover {
            background: #1f46aa;
        }

        .sunbmit_btn {
            background: #FFA432;
            margin-left: 20px;
        }

        .sunbmit_btn:hover {
            background: #f7b768;
        }
    }
}

