.right-menu-box {
  width: 800px;
  padding: 24px;

  .right-menu-sub-link {
    cursor: pointer;
    color: #222;
    font-weight: 700;
    margin-bottom: 4px;

    &:hover {
      color: #004bd8;
    }
  }

  .right-menu-link {
    cursor: pointer;
    margin-bottom: 8px;
    color: #666;

    &:hover {
      color: #004bd8;
    }
  }
}

// 新版格式
.right-menu-box-new {
  width: 400px;
  padding: 20px;

  .right-menu-sub-link-new {

    cursor: pointer;
    color: #333333;
    font-weight: 700;
    margin-bottom: 10px;

    font-family: Konnect;

    &:hover {
      color: #004bd8;
    }
  }

  .right-menu-link-new {

    cursor: pointer;
    margin-bottom: 10px;
    color: #333333;

    font-family: Konnect;

    &:hover {
      color: #004bd8;
    }
  }
}

.component-menu-list-new {
  margin-right: 0px;
}

.er_text {
  font-size: 12px;
  text-align: center;
}

.erweima_css {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .er_iamge_css {
    width: 140px;
    height: 140px;
    display: inline-block;
    border: 1px solid #ccc;
  }
}

// 右侧商家模块
.storeCss {
  width: 320px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 0px;
  background-color: #fff;

  .content_store {
    height: 100%;
    position: relative;

    .css-ulkcep-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 4px;
    }

    .storeListBox {
      height: 448px;
      overflow: scroll;
      
      .itemStore{
        display: flex;
        align-items: center; 
        margin-top: 10px;
        padding: 5px;
        
        cursor: pointer;
        .imageStore{
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 1px solid #E5E5E5;
          img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .rightItemStore{
          width: 155px;
          padding-left: 5px;
        }
      }

      .itemStore:hover{
        background-color: #f5f5f5;
      }
    }

    .storeListBox::-webkit-scrollbar {
      width: 5px;
      height: 30px;
      
    }

    .moreStoreBox {
      position: absolute;
      bottom: 3px;
      height: 59px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #ccc;
      cursor: pointer;
      .toMoreStore {
        width: 210px;
        height: 40px;
        border: 1px solid #ccc;
        text-align: center;
        padding-top: 8px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
      }
      .toMoreStore:hover{
        color: #004bd8;
      }
    }
  }
}